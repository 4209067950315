<template>
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :disable-position-center="true"
    :modal-show="modal"
    :modal-width="60"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span>Attach File</span>
    </template>
    <div>
      <div class="text-xs md:text-base">
        <div class="flex lg:gap-3 mt-3 flex-col lg:flex-row">
          <div class="flex flex-1 gap-2">
            <div class="font-bold">Title</div>
            <div class="text-primary-grey-light">:{{ currentExpense.title }}</div>
          </div>
          <div class="flex flex-1 gap-2">
            <div class="font-bold">Amount</div>
            <div class="text-primary-grey-light">:{{ currentExpense.amount }}</div>
          </div>
        </div>
        <div class="flex lg:gap-3 flex-col lg:flex-row flex-wrap mt-5">
          <div class="flex flex-1 gap-2">
            <div class="font-bold">Spent At</div>
            <div class="text-primary-grey-light">:{{ formatDate(currentExpense.spent_at) }}</div>
          </div>
          <div class="flex flex-1 gap-2">
            <div class="font-bold">Payment through</div>
            <div class="text-primary-grey-light">:{{ currentExpense.payment_method }}</div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <div class="flex gap-3 rtl:gap-x-4">
        <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
        <div>
          <UiInput
            id="input-type-file-single"
            type="file"
            class="border-primary-green"
            placeholder=" Choose File"
            multiple="multiple"
            :no-error="true"
            @change="addNew"
          />
        </div>
      </div>
    </template>
  </UiModalContainer>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import { formatDate } from '@src/utils/moment.util.js'
import { mapActions, mapState } from 'vuex'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  components: {
    UiModalContainer,
    UiInput,
    UIButton,
  },
  props: {
    modal: { type: Boolean },
    currentExpense: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {}
  },
  computed: {
    ...mapState('layout', ['isLoading']),
  },

  methods: {
    formatDate,
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },

    addNew(file) {
      const data = new FormData()
      data.append('expense_id', this.currentExpense.id)
      data.append('files[]', file)
      this.addExtraAttachment(data).then(() => {
        this.$emit('toggle', true)
      })
    },
    ...mapActions('expense', ['addExtraAttachment']),
  },
}
</script>

<style lang="scss" scoped>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
.active {
  .step-counter {
    color: var(--white);
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    box-shadow: 0 7px 14px var(--primary-green-dark);
  }
  .step-info {
    color: var(--text-color);
  }
}
</style>
