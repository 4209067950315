<template>
  <div>
    <ValidationObserver>
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="modal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <span>Files</span>
        </template>
        <template v-slot>
          <div class="main-content bg-primary-white p-5">
            <div class="mt-5 pb-0.5 rounded-lg border border-primary-grey">
              <div class="overflow-x-auto">
                <table
                  class="w-full table-auto whitespace-nowrap border-b border-primary-grey mb-0.5"
                >
                  <thead class="bg-bright-gray">
                    <tr class="text-left">
                      <th
                        v-for="head in tHeads"
                        :key="head"
                        class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 ltr:pl-4 rtl:pl-0 ltr:pr-0"
                      >
                        <span
                          class="ltr:border-r rtl:border-l border-primary-grey flex items-center gap-1"
                        >
                          <span>{{ head }}</span>
                        </span>
                      </th>

                      <th class="table_action_th">
                        <span>Remove</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filesList"
                      :key="index"
                      class="border-t border-primary-grey text-left"
                    >
                      <td
                        class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                      >
                        <span class="font-roboto font-normal text-text-color text-sm">
                          {{ item.file.file_name }}
                        </span>
                      </td>

                      <td
                        class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                      >
                        <span class="font-roboto font-normal text-text-color text-sm">
                          {{ showFileType(item) }}
                        </span>
                      </td>
                      <td
                        class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
                      >
                        <a
                          :href="item.url"
                          class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
                          download
                        >
                          <span>Download</span>
                        </a>
                      </td>
                      <td class="border-primary-grey w-10 px-5">
                        <span
                          class="font-roboto flex justify-center font-normal text-text-color text-sm cursor-pointer"
                          @click="toggleConfirmationModal(item, index)"
                        >
                          <icon
                            class="icon ltr:mr-2 rtl:ml-2"
                            icon="cross"
                            color="text-placeholder"
                            height="20"
                            width="20"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
        </template>
      </UiModalContainer>
    </ValidationObserver>

    <UIConfirmationModal
      v-if="confirmation"
      :modal="confirmation"
      message="Are you sure you want remove "
      :name="currentItem.file_name"
      button-text="Confirm"
      heading="R_FILE"
      @cancel="toggleConfirmationModal"
      @confirm="removeAttachment"
    />
  </div>
</template>

<script>
import { Form as ValidationObserver } from 'vee-validate'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapState, mapActions } from 'vuex'
import icon from '@components/icons/icon.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import { objectDeepCopy } from '@src/utils/generalUtil.js'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    icon,
    UIConfirmationModal,
    UIButton,
  },
  props: {
    modal: { type: Boolean },
    edit: { type: Object, default: () => {} },
    files: { type: Array, default: () => [] },
  },
  emits: ['toggle'],
  data() {
    return {
      tHeads: ['Title', 'File Type', 'Download'],
      confirmation: false,
      currentItem: null,
      currentIndex: null,
      filesList: null,
    }
  },
  computed: {
    ...mapState('layout', ['isLoading']),
    showFileType() {
      return (item) => item.file.file_name.split('.').at(-1)
    },
  },
  watch: {
    files: {
      immediate: true,
      handler(value) {
        this.filesList = objectDeepCopy(value)
      },
    },
  },

  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          break
        case 'save':
          break
      }
    },

    removeAttachment(item) {
      this.removeAttachmentFromExpense(this.currentItem.id).then(() => {
        this.confirmation = false
        this.filesList.splice(this.currentIndex, 1)
      })
    },

    toggleConfirmationModal(item, idx) {
      this.confirmation = !this.confirmation
      this.currentItem = item
      this.currentIndex = idx
      if (!this.confirmation) this.currentItem = null
    },

    ...mapActions('expense', ['removeAttachmentFromExpense']),
  },
}
</script>
