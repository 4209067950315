<template>
  <ValidationObserver @submit="newExpense">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="!edit">Add Expense</span>
        <span v-else>Edit Expense</span>
      </template>
      <template v-slot>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="expenseData.expense_category_id"
            title="EXPENSE_CATEGORY"
            label="title"
            :options="categories"
            rules="required"
            :clearable="false"
            class="flex-1"
            reduce="id"
          />
          <UiInput
            v-model="expenseData.title"
            type="text"
            name="EXP_TITLE"
            title="EXP_TITLE"
            label="EXP_TITLE"
            placeholder="EXP_TITLE"
            rules="required"
            class="flex-1"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiDatePicker
            v-model="expenseData.spent_at"
            rules="required"
            class="flex-1"
            :date-mode="DATE_MODE.END_DATE_TIME"
            title="Date"
          />
          <UiInput
            v-model="expenseData.description"
            type="text"
            name="Expense Description"
            title="Expense Description"
            label="Expense Description"
            placeholder="Expense Description"
            class="flex-1"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiInput
            v-model="expenseData.amount"
            type="text"
            name="Amount"
            title="Amount"
            label="Amount"
            placeholder="Amount"
            rules="required|numeric"
            class="flex-1"
          />
          <UiInput
            v-model="expenseData.payment_method"
            type="text"
            name="Payment Method"
            title="Payment Method"
            rules="required"
            label="Payment Method"
            placeholder="Payment Method"
            class="flex-1"
          />
        </InputFieldWrapper>
        <InputFieldWrapper>
          <UiSingleSelect
            v-model="expenseData.campus_id"
            title="Campuses"
            label="title"
            :options="campuses ? campuses : []"
            :clearable="false"
            :disabled="!!currentCampusScope"
            class="flex-1"
            reduce="id"
          />
        </InputFieldWrapper>

        <div v-if="!edit" class="flex lg:gap-3 flex-col gap-5 lg:flex-row">
          <div
            class="flex-1 justify-between border py-2 lg:py-0 lg:h-12 h-auto border-primary-grey rounded-md flex items-center lg:w-4/5 w-full px-2"
          >
            <BadgeDisplay
              v-if="tempAttachmentsForChips.length"
              class="pl-10"
              label="title"
              :options="tempAttachmentsForChips"
            />
            <div v-else class="text-text-placeholder opacity-40 text-base pl-3">Selected Files</div>
            <div v-if="tempAttachmentsForChips.length" @click="attachements = []">
              <icon
                class="icon cursor-pointer"
                color="text-grey"
                icon="crossCircle"
                height="20"
                width="20"
              />
            </div>
          </div>
          <div>
            <UiInput
              id="input-type-file-expense"
              type="file"
              class="border-none upload-file"
              rules="size:500"
              name="UPLOAD"
              title="UPLOAD"
              :hide-title="true"
              placeholder=" Choose File"
              multiple="multiple"
              @change="changeImage"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>Save</template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import { objectDeepCopy } from '@/src/utils/generalUtil'
import { formatDate } from '@utils/moment.util'
import DATETIME_CONSTANT, { DATE_MODE } from '@src/constants/date-time-constants.js'
import icon from '@src/components/icons/icon.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    UiInput,
    ValidationObserver,
    UiDatePicker,
    BadgeDisplay,
    icon,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      DATE_MODE,
      DATETIME_CONSTANT,
      dashboard: 'dashboard',
      categories: [],
      expenseData: {
        expense_category_id: '',
        title: '',
        description: '',
        spent_at: '',
        amount: '',
        campus_id: '',
        payment_method: '',
      },
      attachements: [],
      tempAttachmentsForChips: [],
    }
  },
  computed: {
    ...mapState('layout', ['campuses', 'isLoading', 'currentCampusScope']),
  },
  watch: {
    attachements: {
      handler(value) {
        this.tempAttachmentsForChips = []
        value.forEach((attachment) => {
          this.tempAttachmentsForChips.push({ title: attachment.name })
        })
      },
    },
  },
  created() {
    if (this.currentCampusScope) {
      this.expenseData.campus_id = this.currentCampusScope.id
    }
    if (this.edit) this.setexpenseData()
    this.getCategories()
  },
  methods: {
    ...mapActions('expense', ['addNewExpense', 'getExpenseCategories', 'updateExistingExpense']),
    getCategories() {
      this.getExpenseCategories().then((res) => {
        this.categories = res.records
      })
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'confirm':
          this.$emit('toggle')
          break
      }
    },

    changeImage(file) {
      this.attachements.push(file)
    },

    addExpense() {
      const data = new FormData()
      data.append('title', this.expenseData.title)
      data.append('description', this.expenseData.description)
      data.append('amount', this.expenseData.amount)
      data.append('payment_method', this.expenseData.payment_method)
      data.append('spent_at', this.expenseData.spent_at)
      data.append('expense_category_id', this.expenseData.expense_category_id)
      if (this.expenseData.campus_id) data.append('campus_id', this.expenseData.campus_id)

      for (let i = 0; i < this.attachements.length; i++) {
        data.append('files[]', this.attachements[i])
      }
      this.addNewExpense(data).then((response) => {
        this.$emit('toggle', true)
      })
    },

    updateExpense() {
      this.$store.commit('layout/IS_LOADING', true)
      this.expenseData.spent_at = formatDate(
        this.expenseData.spent_at,
        DATETIME_CONSTANT.DATE_TIME_24,
      )
      let payload = {
        expense: this.expenseData,
        id: this.edit?.id,
      }
      this.updateExistingExpense(payload)
        .then((response) => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },

    newExpense() {
      if (this.edit) this.updateExpense()
      else this.addExpense()
    },

    setexpenseData() {
      this.expenseData.title = this.edit?.title
      this.expenseData.description = this.edit?.description
      this.expenseData.campus_id = this.edit?.campus_id
      this.expenseData.spent_at = this.edit?.spent_at
      this.expenseData.payment_method = this.edit?.payment_method
      this.expenseData.amount = this.edit?.amount
      this.expenseData.expense_category_id = this.edit?.expense_category_id
    },
  },
}
</script>
