<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="EXPS"
      tooltip-title="EXPS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && noRecord">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH v-for="head in tableHeaders" :key="head">
            <span v-i18n="dashboard">
              {{ head }}
            </span>
          </TH>
          <TH v-if="!currentCampusScope">
            <span>Campus</span>
          </TH>
          <TH>Actions</TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(expense, index) in expensesList" :key="index">
          <TD>
            {{ expense.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ expense.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ expense.amount || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ expense.payment_method || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD>
            {{ $filters.formattedDate(expense.spent_at) }}
          </TD>
          <TD>
            {{ expense.expense_category.title }}
          </TD>

          <TD
            @click="expense.expense_attachments.length && showAllFiles(expense.expense_attachments)"
          >
            <BadgeDisplay
              :options="expense.expense_attachments"
              :nested-label="['file', 'file_name']"
              :image="true"
            />
          </TD>
          <TD v-if="!currentCampusScope">
            <BadgeDisplay :options="expense.campus" :image="true" />
          </TD>
          <TD>
            <TableAction
              :current-user="expense"
              :idx="index"
              :action-list="actionList"
              @action="expenseActions"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(expensesCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
    <ExpenseModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentExpense"
      @toggle="toggleExpenseModal"
    />
    <SingleAttachFileModal
      v-if="newAttachmentModal"
      :modal="newAttachmentModal"
      :current-expense="currentExpense"
      @toggle="toggleNewAttachmentModal"
    />
    <AttachedFilesModal
      v-if="showMultiFilesModal"
      :modal="showMultiFilesModal"
      :files="currentSelectedFiles"
      @toggle="showAllFiles"
    />
    <UIConfirmationModal
      v-if="confirmation"
      heading="DELETE_EXPENSE"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :name="currentExpense.title"
      remaining-message="Expense ?"
      @cancel="toggleConfirmationModal"
      @confirm="deactivateExpense"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { mapState, mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import ExpenseModal from '@views/admin/modals/AddExpenseModal.vue'
import TableAction from '@/src/components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import generalConstants from '@src/constants/general-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import Loader from '@components/BaseComponent/Loader.vue'
import BadgeDisplay from '@components/UiElements/UiBadgeDisplay.vue'
import AttachedFilesModal from '@views/expense-manager/AttachedFilesModal.vue'
import SingleAttachFileModal from '@views/expense-manager/AttachNewFileModal.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import NoRecordFound from '@src/components/BaseComponent/NoRecordFound.vue'
import { findCurrencyById } from '@utils/generalUtil'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'Expenses',
  components: {
    TitleWrapper,
    ExpenseModal,
    TableAction,
    Pagination,
    Loader,
    AttachedFilesModal,
    BadgeDisplay,
    SingleAttachFileModal,
    UIConfirmationModal,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      isEmpty,
      isLoading: false,
      dashboard: 'dashboard',
      tableHeaders: ['Title', 'Description', 'Amount', 'EV_PT', 'Date', 'E_CAT', 'EV_F'],
      currentExpense: null,
      actionList: [{ name: 'Edit' }, { name: 'EV_ANF' }, { name: 'Delete' }],
      showPagination: false,
      filteredRecordLimit: 10,
      expensesCounts: null,
      expensesList: [],
      noRecord: false,
      showMultiFilesModal: false,
      currentSelectedFiles: null,
      confirmation: false,
      newAttachmentModal: false,
    }
  },
  page: {
    title: 'Expense | Details',
    meta: [
      {
        name: 'description',
        content: 'Manage expenses ',
      },
    ],
  },
  computed: {
    currencySymbol() {
      return findCurrencyById()?.symbol
    },
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
      showModal: (state) => state.layout.showModal,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.filterRecord()
      },
    },
  },
  created() {
    this.setRightbar()
    this.filterRecord()
  },
  methods: {
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentExpense = null
    },
    filterRecord(range) {
      this.isLoading = true
      let payload = {
        skip: range?.skip || generalConstants.RECORD_SKIP,
        limit: range?.limit || generalConstants.RECORD_LIMIT,
      }
      this.getExpenses(payload)
        .then((response) => {
          this.expensesList = response?.records || []
          this.noRecord = isEmpty(this.expensesList)
          this.expensesCounts = response?.meta?.total_records || 0
          this.showPagination = this.expensesCounts > 10
          this.setRightbar(response.stats.total_expenses)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    setRightbar(totalExpenses) {
      this.setRightbarContent({
        header: {
          title: 'RB_E',
          buttons: [
            {
              title: 'RB_AE',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_NO_EXPENSES',
                currency: this.currencySymbol,
                value: totalExpenses || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total Amount of expenses made in an institute',
              },
            ],
          },
        ],
      })
    },

    addAttachment(file, expense) {
      const data = new FormData()
      data.append('expense_id', expense.id)
      data.append('files[]', file)
      this.addExtraAttachment(data).then(() => {
        this.filterRecord()
      })
    },

    toggleExpenseModal(payload) {
      this.$store.dispatch('layout/setShowModal', false)
      this.currentExpense = null
      if (payload) this.filterRecord()
    },

    showAllFiles(files) {
      this.showMultiFilesModal = !this.showMultiFilesModal
      this.currentSelectedFiles = files
      if (!this.showMultiFilesModal) {
        this.currentSelectedFiles = null
        this.filterRecord()
      }
    },

    expenseActions(action, current) {
      this.currentExpense = current
      switch (action) {
        case 'Edit':
          this.toggleExpenseEditModal()
          break
        case 'EV_ANF':
          this.toggleNewAttachmentModal()
          break
        case 'Delete':
          this.toggleConfirmationModal()

          break
      }
    },

    toggleExpenseEditModal() {
      this.$store.dispatch('layout/setShowModal', true)
    },

    toggleNewAttachmentModal(payload) {
      this.newAttachmentModal = !this.newAttachmentModal
      if (!this.newAttachmentModal) this.currentExpense = null
      if (payload) this.filterRecord()
    },

    deactivateExpense() {
      this.deleteExpenses(this.currentExpense.id)
        .then(() => {
          this.filterRecord()
          this.confirmation = false
        })
        .finally(() => {
          this.currentExpense = null
        })
    },

    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('expense', ['getExpenses', 'deleteExpenses', 'addExtraAttachment']),
  },
}
</script>
